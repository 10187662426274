import { useCallback, useEffect, useState } from 'react';
import { EntityType, KtruResult, OkpdResult } from '@/api/okpdApi/okpdApiTypes';
import { CharacteristicsParam, TokenBasedPagination } from '@/types/types';
import { useGetKtruList, useGetOkpdList } from '@/api/okpdApi/okpdApi';

type Filter = {
  searchString: string;
  code: string;
  characteristics?: CharacteristicsParam[];
};

export type UseOkpdKtruListProps = {
  searchString?: string;
  code?: string;
  characteristics: CharacteristicsParam[];
  listType: EntityType;
};

const DEFAULT_PAGINATION_COUNT = 10;

export const useOkpdKtruList = <K extends OkpdResult | KtruResult>({
  searchString = '',
  code = '',
  listType,
  characteristics,
}: UseOkpdKtruListProps) => {
  const useData = {
    [EntityType.OKPD]: useGetOkpdList,
    [EntityType.KTRU]: useGetKtruList,
  }[listType];

  const [isMoreLoading, setIsMoreLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [pagination, setPagination] = useState<TokenBasedPagination>({
    count: DEFAULT_PAGINATION_COUNT,
    token: null,
  });
  const [filter, setFilter] = useState<Filter>({
    searchString,
    code,
    characteristics,
  });
  const [list, setList] = useState<K[]>([]);

  const { data: { results, hasMoreResults, token = null } = {}, isLoading } = useData(
    filter,
    pagination,
  );

  const isListLoading = isLoading && !isMoreLoading;

  useEffect(() => {
    setList([]);
    setPagination((prev) => ({ ...prev, token: null }));
    setFilter((prev) => ({
      ...prev,
      searchString,
      characteristics,
    }));
    setHasMore(false);
  }, [searchString, characteristics]);

  useEffect(() => {
    if (results) {
      setList((prev) => [...prev, ...(results as K[])]);
      setIsMoreLoading(false);
      setHasMore(hasMoreResults ?? false);
    }
  }, [hasMoreResults, results]);

  const handleShowMore = useCallback(() => {
    setPagination((prev) => ({ ...prev, token }));
    setIsMoreLoading(true);
  }, [token]);

  return {
    list,
    isListLoading,
    isMoreLoading,
    handleShowMore,
    hasMoreResults: hasMore,
  };
};
