import { useOkpdKtruList, UseOkpdKtruListProps } from '@/hooks';
import { EntityType, KtruResult, OkpdResult } from '@/api/okpdApi/okpdApiTypes';
import { useCallback, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { pageRoutes } from '@/app/pageRoutes';
import {
  OkpdKtruPageTypes,
  SetOkpdKtruModalPageParamsType,
} from '@/components/OkpdFlowModal/types';

type Props = Pick<UseOkpdKtruListProps, 'searchString' | 'characteristics'> & {
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

/*
 * Добавляет в историю страницу списка ОКПД перед редиректом на
 * детальную страницу ОКПД или КТРУ. Это необходимо, чтобы при
 * нажатии кнопки "Назад" в браузере пользователь попадал на
 * страницу списка ОКПД, а не возвращался снова на детальную страницу.
 */
const addOkpdPageToHistorySilently = () => window.history.replaceState(null, '', pageRoutes.okpd2);

export const useRedirectOnSingleOkpdKtruResult = ({
  searchString,
  characteristics,
  setModalPageParams,
}: Props) => {
  const router = useRouter();

  const { list: okpdList } = useOkpdKtruList<OkpdResult>({
    listType: EntityType.OKPD,
    searchString,
    characteristics,
  });
  const { list: ktruList } = useOkpdKtruList<KtruResult>({
    listType: EntityType.KTRU,
    searchString,
    characteristics,
  });

  const isRedirectToOkpdAvailable = okpdList.length === 1 && !ktruList.length;
  const isRedirectToKtruAvailable = ktruList.length === 1 && !okpdList.length;

  const redirectToOkpd = useCallback(() => {
    if (setModalPageParams) {
      setModalPageParams((prev) => ({
        ...prev,
        code: okpdList[0].code,
        page: OkpdKtruPageTypes.OKPD,
      }));
    } else {
      addOkpdPageToHistorySilently();
      router.push(`${pageRoutes.okpd2}/${okpdList[0].code}`);
    }
  }, [okpdList, router, setModalPageParams]);

  const redirectToKtru = useCallback(() => {
    if (setModalPageParams) {
      setModalPageParams((prev) => ({
        ...prev,
        code: ktruList[0].code,
        page: OkpdKtruPageTypes.KTRU,
      }));
    } else {
      addOkpdPageToHistorySilently();
      router.push(`${pageRoutes.ktru}/${ktruList[0].code}`);
    }
  }, [ktruList, router, setModalPageParams]);

  useEffect(() => {
    if (characteristics.length) {
      return;
    }
    if (isRedirectToOkpdAvailable) {
      return redirectToOkpd();
    }
    if (isRedirectToKtruAvailable) {
      return redirectToKtru();
    }
  }, [
    characteristics.length,
    isRedirectToKtruAvailable,
    isRedirectToOkpdAvailable,
    redirectToKtru,
    redirectToOkpd,
  ]);
};
