import { memo, useCallback } from 'react';
import { ExportFileExtensionType } from '@/components/ExportFile/types';
import { Button, Flex, Select, SelectProps } from 'antd';
import styles from './ExportFile.module.scss';
import Export from 'public/icons/export.svg';

type Props = {
  onExportButtonClick: (fileType?: ExportFileExtensionType) => void;
  buttonText?: string;
  flexGap?: number;
};

const labelRender: SelectProps['labelRender'] = ({ value }) => (
  <Flex
    align="center"
    gap={4}
  >
    <div className={styles.text}>Тип файла:</div>
    <div>{value}</div>
  </Flex>
);

export const ExportFile = memo(
  ({ onExportButtonClick, buttonText = 'Сформировать отчёт', flexGap = 12 }: Props) => {
    const handleExportButtonClick = useCallback(() => {
      onExportButtonClick('xslx');
    }, [onExportButtonClick]);

    return (
      <Flex
        align="center"
        gap={flexGap}
      >
        <Select
          labelRender={labelRender}
          defaultValue={'Excel'}
          variant="borderless"
          // suffixIcon={<VIcon className="v-icon" />} // Временно скрываем (SARK-613). Потом вернуть VIcon
          suffixIcon={null}
          open={false} // Временно скрываем (SARK-613). Потом удалить
        />
        <Button
          type="primary"
          icon={<Export />}
          className="button-with-icon"
          onClick={handleExportButtonClick}
        >
          {buttonText}
        </Button>
      </Flex>
    );
  },
);
ExportFile.displayName = 'ExportFile';
