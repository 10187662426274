import { NmckType } from '@/api/nmckApi/nmckApiTypes';
import { pageRoutes, PageRouteValuesType } from '@/app/pageRoutes';

export const nmckData: Record<NmckType, { label: string; route: PageRouteValuesType }> = {
  [NmckType.MARKET]: { label: 'Анализ рынка', route: pageRoutes.nmckAnalysis },
  [NmckType.DRUGS]: { label: 'Лекарства', route: pageRoutes.nmckDrugs },
  [NmckType.GUARD]: { label: 'Охрана', route: pageRoutes.nmckGuard },
  [NmckType.MED_DEVICES]: {
    label: 'Медицинские изделия',
    route: pageRoutes.nmckMedDevices,
  },
};
