export const eventsGTM = {
  // okpd / ktru
  okpdCommonPageView: 'okpdCommonPageView',
  okpdDetailPageView: 'okpdDetailPageView',
  ktruDetailPageView: 'ktruDetailPageView',
  okpdSearch: 'okpdSearch',
  okpdCardClick: 'okpdCardClick',
  ktruCardClick: 'ktruCardClick',
  npaTabView: 'npaTabView',
  ktruTabView: 'ktruTabView',
  npaModalOpen: 'npaModalOpen',
  checkedCharacteristic: 'checkedCharacteristic',
  copyCharacteristicsAll: 'copyCharacteristicsAll',
  copyCharacteristicsSelected: 'copyCharacteristicsSelected',
  copyCharacteristicsRow: 'copyCharacteristicsRow',
  suppliersTabView: 'suppliersTabView',
  requestKP: 'requestKP',

  // nmck analysis
  nmckNameChange: 'nmckNameChange',
  nmckResponsibleChange: 'nmckResponsibleChange',
  nmckRowNameChange: 'nmckRowNameChange',
  nmckRowPriceChange: 'nmckRowPriceChange',
  nmckRowOkpdChangeBySelect: 'nmckRowOkpdChangeBySelect',
  nmckRowOkpdChangeByModal: 'nmckRowOkpdChangeByModal',
  nmckRowKtruChangeByModal: 'nmckRowKtruChangeByModal',
  nmckEisOpen: 'nmckEisOpen',
  nmckChangeUndeterminedAmount: 'nmckChangeUndeterminedAmount',
  nmckAddRow: 'nmckAddRow',
  nmckContractSelect: 'nmckContractSelect',
  nmckDownloadExcel: 'nmckDownloadExcel',
  // nmckImportClick: 'nmckImportClick', // Кнопка "Импортировать"
  nmckNewCalculationClick: 'nmckNewCalculationClick',

  // nmck drugs
  drugsLimitPriceSourceChange: 'drugsLimitPriceSourceChange',
  drugsLimitPriceGOLinkClick: 'drugsLimitPriceGOLinkClick',
  drugsLimitPriceRefreshGOButtonClick: 'drugsLimitPriceRefreshGOButtonClick',
};
