import { OkpdResult } from '@/api/okpdApi/okpdApiTypes';
import { Flex } from 'antd';
import { NPAList, StandardContract } from '@/widgets/OkpdKtru/components';
import cardStyles from '../Card.module.scss';
import { memo, useCallback } from 'react';
import { pageRoutes } from '@/app/pageRoutes';
import {
  OkpdKtruPageTypes,
  SetOkpdKtruModalPageParamsType,
} from '@/components/OkpdFlowModal/types';
import { OkpdLink } from '@/components/OkpdFlowModal/OkpdLink/OkpdLink';
import { eventsGTM, sendEventGTM } from '@/metrika';

type OkpdCardProps = {
  okpd: OkpdResult;
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

export const OkpdCard = memo(({ okpd, setModalPageParams }: OkpdCardProps) => {
  const handleCodeLinkClick = useCallback(() => {
    sendEventGTM(eventsGTM.okpdCardClick, {
      value: okpd.name,
      info: setModalPageParams ? 'modal' : 'page',
    });
    setModalPageParams &&
      setModalPageParams((prev) => ({
        ...prev,
        page: OkpdKtruPageTypes.OKPD,
        code: okpd.code,
        searchString: '',
      }));
  }, [okpd.code, okpd.name, setModalPageParams]);

  return (
    <Flex
      vertical
      gap={16}
      className={cardStyles.card}
    >
      <Flex
        vertical
        gap={8}
      >
        <OkpdLink
          href={`${pageRoutes.okpd2}/${okpd.code}`}
          isLink={!setModalPageParams}
          className={cardStyles.cardLink}
          onClick={handleCodeLinkClick}
        >
          {okpd.code}
        </OkpdLink>
        <div
          className={cardStyles.cardName}
          dangerouslySetInnerHTML={{ __html: okpd.name }}
        />
      </Flex>
      {okpd.ciName && (
        <div className={cardStyles.cardCiName}>
          С этим кодом закупали: <span dangerouslySetInnerHTML={{ __html: okpd.ciName }} />
        </div>
      )}
      <NPAList npa={okpd.npa} />
      <Flex
        justify={'space-between'}
        align={'center'}
      >
        <StandardContract standardContract={okpd.standardContracts} />
      </Flex>
    </Flex>
  );
});

OkpdCard.displayName = 'OkpdCard';
