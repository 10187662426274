import { notification } from 'antd';
import { NmckDrugsByIdResponse } from '@/api/nmckDrugsApi/nmckDrugsApiTypes';

export const successCreateRecurrencyNotification = (
  recurrencyBadge: NmckDrugsByIdResponse['recurrencyBadge'],
) => {
  notification.success({
    message: `${recurrencyBadge ?? 'Повторный'} расчет успешно создан`,
  });
};

export const failedCreateRecurrencyNotification = () => {
  notification.error({
    message: 'Произошла ошибка при создании повторного расчёта',
  });
};
