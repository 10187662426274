import { NmckContractsFilter, RegionType } from '@/components/NmckGrid/NmckGridContracts/types';

export const initialContractsFilterState: NmckContractsFilter = {
  purchaseMethodIds: [],
  priceFrom: '',
  priceUpTo: '',
  unitMeasure: undefined,
  conclusionDateStart: undefined,
  conclusionDateEnd: undefined,
  executionDateStart: undefined,
  executionDateEnd: undefined,
  countFrom: '',
  countUpTo: '',
  regionIds: [],
  regionType: RegionType.SUPPLIER,
  characteristics: [],
};

export const regionTypeOptions = [
  { label: 'Поставщик', value: RegionType.SUPPLIER },
  { label: 'Заказчик', value: RegionType.BUYER },
];
