import {
  Dispatch,
  memo,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Button, Modal, ModalProps } from 'antd';
import { OkpdKtruPage } from '@/widgets/OkpdKtru/OkpdKtruPage';
import styles from './OkpdFlowModal.module.scss';
import { OkpdKtruModalPageParams, OkpdKtruPageTypes } from '@/components/OkpdFlowModal/types';
import { OkpdDetailPage } from '@/widgets';
import { KtruDetailPage } from '@/widgets/KtruDetail/KtruDetailPage/KtruDetailPage';

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  searchString: string;
  onAddToCalculation: (pageParams: OkpdKtruModalPageParams) => Promise<unknown>;
};

const modalClassNames: ModalProps['classNames'] = { body: styles.modalBody };

export const OkpdFlowModal = memo(
  ({ isOpen, setIsOpen, searchString, onAddToCalculation }: Props) => {
    const [isAddingToCalculationLoading, setIsAddingToCalculationLoading] = useState(false);
    const [pageParams, setPageParams] = useState<OkpdKtruModalPageParams>({
      page: OkpdKtruPageTypes.LISTS,
      searchString,
      code: '',
    });

    const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);

    const ComputedOkpdPage: ReactNode = useMemo(
      () =>
        ({
          [OkpdKtruPageTypes.LISTS]: (
            <OkpdKtruPage
              modalPageParams={pageParams}
              setModalPageParams={setPageParams}
            />
          ),
          [OkpdKtruPageTypes.OKPD]: (
            <OkpdDetailPage
              code={pageParams.code}
              setModalPageParams={setPageParams}
            />
          ),
          [OkpdKtruPageTypes.KTRU]: (
            <KtruDetailPage
              code={pageParams.code}
              setModalPageParams={setPageParams}
            />
          ),
        })[pageParams.page],
      [pageParams],
    );

    const handleAddToCalculationClick = useCallback(async () => {
      setIsAddingToCalculationLoading(true);
      try {
        await onAddToCalculation(pageParams);
        setIsAddingToCalculationLoading(false);
        handleClose();
      } catch (err) {
        setIsAddingToCalculationLoading(false);
      }
    }, [handleClose, onAddToCalculation, pageParams]);

    const modalFooter = useMemo(
      () => (
        <Button
          className={styles.footerButton}
          size="large"
          type="primary"
          loading={isAddingToCalculationLoading}
          disabled={!pageParams.code}
          onClick={handleAddToCalculationClick}
        >
          Добавить код в расчёт
        </Button>
      ),
      [isAddingToCalculationLoading, handleAddToCalculationClick, pageParams.code],
    );

    useEffect(() => {
      setPageParams((prev) => ({
        ...prev,
        page: OkpdKtruPageTypes.LISTS,
        searchString,
        okpdId: undefined,
        ktruId: undefined,
        code: '',
      }));
    }, [searchString]);

    return (
      <>
        <Modal
          centered
          open={isOpen}
          title="Выбор ОКПД"
          footer={modalFooter}
          onCancel={handleClose}
          width="90%"
          classNames={modalClassNames}
          className={styles.modal}
        >
          {ComputedOkpdPage}
        </Modal>
      </>
    );
  },
);

OkpdFlowModal.displayName = 'OkpdFlowModal';
