import { memo, ReactNode } from 'react';
import styles from './Tag.module.scss';
import cx from 'classnames';

type Props = {
  label: string | ReactNode;
  className?: string;
};

export const Tag = memo(({ label, className }: Props) => {
  return <div className={cx(styles.tag, className)}>{label}</div>;
});

Tag.displayName = 'Tag';
