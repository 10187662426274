import { memo, useCallback } from 'react';
import { Select, SelectProps } from 'antd';
import { ndsOptions } from '@/components/NmckGrid/NmckGridNdsSelect/contstants';
import { NmckRow } from '@/api/nmckApi/nmckApiTypes';
import { updateNmckRow, useGetNmckById } from '@/api/nmckApi/nmckApi';
import styles from './NmckGridNdsSelect.module.scss';

type Props = {
  row: NmckRow;
  nmckId: string;
};

export const NmckGridNdsSelect = memo(({ row, nmckId }: Props) => {
  const { mutate: refreshGridTable } = useGetNmckById(nmckId);

  const handleSelectChange: SelectProps['onChange'] = useCallback(
    async (value: number) => {
      await updateNmckRow(nmckId, { ...row, nds: value });
      await refreshGridTable();
    },
    [nmckId, refreshGridTable, row],
  );

  return (
    <Select
      value={row.nds}
      className={styles.select}
      options={ndsOptions}
      onChange={handleSelectChange}
    />
  );
});

NmckGridNdsSelect.displayName = 'NmckGridNdsSelect';
