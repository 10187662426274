import useSWR from 'swr';
import { getData } from '@/api/commonApi/commonApi';
import { ContractDocument, CustomersResponse } from './docsApiTypes';
import { SearchParams } from '@/widgets/Docs/context/reducer';

export const useGetCustomers = (searchString?: string) =>
  useSWR(
    `/contract-items-with-docs-customers?searchstring=${searchString}`,
    getData<CustomersResponse>,
    {
      revalidateOnFocus: false,
    },
  );

export const useGetContractDocuments = (state: SearchParams) => {
  const params = new URLSearchParams();
  if (state.code) params.append('code', state.code);
  if (state.startDate) params.append('publishedFrom', state.startDate);
  if (state.endDate) params.append('publishedTo', state.endDate);
  if (state.customers.length !== 0) {
    for (const customer of state.customers) {
      params.append('customerIds', customer.toString());
    }
  }
  if (state.regions.length !== 0) {
    for (const region of state.regions) {
      params.append('regionId', region.toString());
    }
  }
  state.eis ? params.append('eisId', state.eis.toString()) : params.delete('eisId');
  state.rank ? params.append('rank', state.rank.toString()) : params.delete('rank');
  if (state.itemsPerPage) params.append('pageSize', state.itemsPerPage.toString());

  const queryString = `?${params.toString()}`;

  return useSWR(
    state.code && `/contract-items-with-docs${queryString}`,
    getData<ContractDocument[]>,
    {
      keepPreviousData: true,
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
  );
};
