import styles from './Badge.module.scss';
import cx from 'classnames';
import { memo, useMemo } from 'react';

type BadgeProps = {
  count: number;
  maxCount?: number;
  className?: string;
};

export const Badge = memo(({ count, className, maxCount }: BadgeProps) => {
  const formattedCount: string | number = useMemo(
    () => (maxCount && count > maxCount ? `${maxCount}+` : count),
    [count, maxCount],
  );

  return (
    <div className={cx(styles.badge, className, { [styles.disabled]: !count })}>
      {formattedCount}
    </div>
  );
});

Badge.displayName = 'Badge';
