import { memo, useCallback, useContext } from 'react';
import { Flex, Pagination, Select } from 'antd';
import Vicon from '/public/icons/v.svg';
import { ReestrDirectPageResultsTable } from './components/ReestrDirectPageResultsTable/ReestrDirectPageResultsTable';
import { resultsTableColumns } from './data';
import styles from './ReestrDirectPageResults.module.scss';
import { showOnPageOptions } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/constants';
import {
  DirectContract,
  DirectContractCauses,
  DirectContractsResponse,
} from '@/api/reestrDirectApi/reestrDirectApiTypes';
import { ReestrDirectContext } from '@/widgets/ReestrDirect/context/context';
import { actions } from '@/widgets/ReestrDirect/context/actions';
import { getXlsx } from '@/api/reestrDirectApi/reestrDirectApi';
import { useSortableTableColumns } from '@/components/Table/TableColumnsSettings/hooks';
import { TableColumnsSettings } from '@/components/Table/TableColumnsSettings/TableColumnsSettings';
import { ExportFile } from '@/components/ExportFile/ExportFile';

const options = showOnPageOptions.slice(0, -1);
const dropdownStyle = {
  width: 'max-content',
};

type ReestrDirectPageResultsProps = {
  contracts: DirectContractsResponse | undefined;
  isContractsLoading: boolean;
  refreshContracts: () => void;
  causes: DirectContractCauses | undefined;
  isCausesLoading: boolean;
};

export const ReestrDirectPageResults = memo(
  ({
    contracts,
    isContractsLoading,
    refreshContracts,
    causes,
    isCausesLoading,
  }: ReestrDirectPageResultsProps) => {
    const { state, dispatch } = useContext(ReestrDirectContext);

    const { orderedColumns, orderedColumnsMap, setOrderedColumnsMap } =
      useSortableTableColumns<DirectContract>({
        columns: resultsTableColumns,
      });
    const filteredOrderedColumns = orderedColumns.filter((col) => col.title);

    const handleShowOnPageSelectChange = useCallback(
      (value: number) => {
        dispatch(actions.itemsPerPageUpdate(value));
      },
      [dispatch],
    );

    const pageChange = useCallback(
      (page: number) => {
        dispatch(actions.currentPageUpdate(page));
      },
      [dispatch],
    );

    return (
      <div className={styles.wrapper}>
        <ReestrDirectPageResultsTable
          contracts={contracts}
          isContractsLoading={isContractsLoading}
          selectedColumns={orderedColumns}
          refreshContracts={refreshContracts}
          causes={causes}
          isCausesLoading={isCausesLoading}
        />
        <div className={styles.settings}>
          <Flex
            align="center"
            gap={42}
          >
            <TableColumnsSettings<DirectContract>
              orderedColumnMap={orderedColumnsMap}
              setOrderedColumnMap={setOrderedColumnsMap}
              columnsData={filteredOrderedColumns}
            />
            <div className={styles.pagination}>
              Страницы:
              <Pagination
                showSizeChanger={false}
                current={state.currentPage}
                total={contracts && contracts.count}
                pageSize={state.itemsPerPage}
                onChange={pageChange}
              />
            </div>
            <div className={styles.sorting}>
              Показывать на странице:
              <Select
                dropdownStyle={dropdownStyle}
                title="Показывать на странице:"
                variant="borderless"
                suffixIcon={
                  <div className={styles.iconV}>
                    <Vicon />
                  </div>
                }
                value={state.itemsPerPage}
                onChange={handleShowOnPageSelectChange}
                options={options}
              />
            </div>
          </Flex>
          <ExportFile
            onExportButtonClick={getXlsx}
            flexGap={24}
          />
        </div>
      </div>
    );
  },
);
ReestrDirectPageResults.displayName = 'ReestrDirectPageResults';
