import useSWR from 'swr';
import { EisAllContractsResponse } from './reestrAllApiTypes';
import { ReestrAllParams } from '@/widgets/ReestrAll/context/reducer';
import { StatusType } from '@/widgets/ReestrAll/types/types';
import { downloadXlsx, fetcher, getData } from '../commonApi/commonApi';
import { setURLParamsByBDUIFilters } from '@/utils/setURLParamsByBDUIFilters';

export const useGetEisAllContracts = (state: ReestrAllParams) => {
  const params = new URLSearchParams();

  params.append('page', String(state.currentPage));
  params.append('count', String(state.itemsPerPage));
  state.status !== StatusType.ALL && params.append('contractStatus', String(state.status));
  state.concluded !== 'ALL' && params.append('paymentsYear', '2017');
  state.search && params.append('searchString', state.search);
  setURLParamsByBDUIFilters(params, state.filters);

  return useSWR(`/eis-all-contracts?${params}`, getData<EisAllContractsResponse>, {
    revalidateOnFocus: false,
    keepPreviousData: true,
  });
};
export const getXlsx = async () => {
  const response = await fetcher('/eis-all-contracts/xlsx');
  await downloadXlsx(response, 'Все закупки из еис');
};
