import { memo, useContext, useMemo, useState } from 'react';
import { Divider } from 'antd';
import { NmckGridContractsNotFound } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsNotFound/NmckGridContractsNotFound';
import { ContractItem, ContractItemsResponse, NmckRow } from '@/api/nmckApi/nmckApiTypes';
import { NmckGridContext } from '@/components/NmckGrid/context/context';
import { useGetContracts } from '@/api/nmckApi/nmckApi';
import styles from './NmckGridContracts.module.scss';
import { NmckContractsFilter } from '@/components/NmckGrid/NmckGridContracts/types';
import { initialContractsFilterState } from '@/components/NmckGrid/NmckGridContracts/constants';
import { NmckGridContractsFilter } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsFilter/NmckGridContractsFilter';
import { useTokenBasedDataList } from '@/hooks';
import { getRegionsFromStorage } from '@/components/NmckGrid/NmckGridContracts/helpers';
import { NmckGridContractsTable } from '@/components/NmckGrid/NmckGridContracts/components/NmckGridContractsTable/NmckGridContractsTable';
import { getCurrentDate, getYearAgoDate } from '@/components/DatePeriodPicker/utils';

type Props = {
  record: NmckRow;
};

export const NmckGridContracts = memo(({ record }: Props) => {
  const { state, dispatch } = useContext(NmckGridContext);
  const [filter, setFilter] = useState<NmckContractsFilter>({
    ...initialContractsFilterState,
    regionIds: getRegionsFromStorage(),
    executionDateStart: getYearAgoDate(),
    executionDateEnd: getCurrentDate(),
  });
  const code = record.okpdCode ?? record.ktruCode;

  const response = useGetContracts({
    row: {
      name: record.name,
      ktruCode: record.ktruCode,
      okpdCode: record.okpdCode,
    },
    filter,
    pagination: state,
  });

  const {
    list,
    hasMoreResults,
    token,
    isLoading,
    originalData: contractsData,
  } = useTokenBasedDataList<ContractItem, ContractItemsResponse>({
    response,
    resetDeps: [dispatch, record.ktruCode, record.okpdCode, record.name, state.pageSize, filter],
  });

  const contracts: ContractItemsResponse = useMemo(
    () => ({
      results: list,
      characteristics: contractsData?.characteristics ?? [],
      token,
      hasMoreResults,
    }),
    [contractsData?.characteristics, hasMoreResults, list, token],
  );

  return (
    <>
      <NmckGridContractsFilter
        filter={filter}
        setFilter={setFilter}
        isContractsLoading={isLoading}
        characteristics={contractsData?.characteristics}
      />
      <div className={styles.divider}>
        <Divider />
      </div>
      {contracts.results.length ? (
        <NmckGridContractsTable
          contracts={contracts}
          isContractsLoading={isLoading}
          record={record}
        />
      ) : (
        <NmckGridContractsNotFound
          isContractsLoading={isLoading}
          code={code}
        />
      )}
    </>
  );
});
NmckGridContracts.displayName = 'NmckGridContracts';
