import { CharacteristicForFilter } from '@/api/okpdApi/okpdApiTypes';
import {
  Characteristic,
  ContractItemConsumer,
  ContractItemKtru,
  ContractItemOkpd,
  ContractItemSupplier,
} from '@/components/NmckGrid/NmckGridContracts/components/types';
import { TokenBasedPageableResponse } from '@/api/commonApi/commonApiTypes';

export enum VariationColor {
  GREEN = 'GREEN',
  RED = 'RED',
}

export enum NmckType {
  MARKET = 'MARKET',
  DRUGS = 'DRUGS',
  GUARD = 'SECURITY',
  MED_DEVICES = 'MED_DEVICES',
}

export enum CalculationPrecision {
  HIGH = 'HIGH',
  CENTS = 'CENTS',
}

export enum CalculationMethod {
  MIN = 'MIN',
  AVG = 'AVG',
  WEIGHED_AVG = 'WEIGHED_AVG',
}

export enum PropositionType {
  KP = 'KP',
  EIS = 'EIS',
}

export enum BadgeType {
  NO_BADGE = 'NO_BADGE',
  HAS_RESTRICTIONS = 'HAS_RESTRICTIONS',
  HAS_INCOMPATIBLE_CODES = 'HAS_INCOMPATIBLE_CODES',
}

export enum NPACellType {
  EMPTY = 'EMPTY',
  APPLIES = 'APPLIES',
  APPLIES_WITH_DETAILS = 'APPLIES_WITH_DETAILS',
  SELECT = 'SELECT',
  SELECT_ACCEPTED = 'SELECT_ACCEPTED',
  SELECT_REJECTED = 'SELECT_REJECTED',
}

export type NmckByIdResponse = {
  id: number;
  versionNumber: number;
  type: NmckType;
  created: string;
  modified: string;
  nmckTotal: number;
  name: string;
  generatedName: string;
  responsible: string;
  rows: NmckRow[];
  calculationPrecision: CalculationPrecision;
  calculationMethod: CalculationMethod;
  badge: BadgeType;
};

export type NmckCompatibilityByIdResponse = {
  header: NmckCompatibilityHeader[];
  rows: NmckCompatibilityRow[];
  result: boolean;
  hasRestrictions: boolean;
};

export type NmckCompatibilityHeader = {
  caption: string;
  columns: {
    code: string;
    title: string;
  }[];
};

export type NmckCompatibilityRow = {
  id: number;
  purchaseNumber: number;
  calculationMethod: string;
  code: string;
  nkmi?: string;
  name?: string;
  cells: NmckCompatibilityCell[];
};

export interface NmckCompatibilityCell {
  type: NPACellType;
  text?: string;
  columnCode: string;
}

export type NmckRow = {
  id: number;
  name: string;
  ktruCode?: string;
  okpdCode?: string;
  propositions: Proposition[];
  unit: string;
  unitCode?: number;
  variation: number;
  variationColor: VariationColor;
  amount: number;
  averagePrice: number;
  minPrice: number;
  nds: number;
  nmck: number;
  nkmiId?: number;
};

export type Proposition = {
  columnNumber: number;
  type: PropositionType;
  price?: number;
  description: string;
  contractId: number;
  forAllRows: boolean;
};

export type UnitsMeasureResponse = UnitsMeasure[];
export type UnitsMeasure = {
  name: string;
  unit: number;
};
export type TreeSelectDataResponse = TreeSelectOption[];

export type TreeSelectOption = {
  id: number;
  name: string;
  children?: TreeSelectOption[];
};

export type NmckResponse = {
  results: Nmck[];
  count: number;
};
export type Nmck = {
  id: number;
  type: NmckType;
  created: string;
  modified: string;
  nmckTotal: number;
  name: string;
  generatedName: string;
  responsible: string;
  recurrencyBadge?: string;
  recurrency?: number;
};

export type SupplierResult = {
  name: string;
  phone: string;
  email: string;
  isSmp: boolean;
  isProm: boolean;
  contractsWithoutFines: boolean;
  inn: number;
  kpp: string;
  address: string;
  lastContractRegNum: string;
  suppliedCodes: string[];
  supplyName: string;
  contractsFinished: number;
  price: number;
};

export type ContractItemsResponse = TokenBasedPageableResponse<ContractItem> & {
  characteristics: CharacteristicForFilter[];
};

export type Contract = {
  reestrNumber: string;
  price: number;
  updateDate: string;
  conclusionDate: string;
  executionDate: string;
  purchaseMethod?: TreeSelectOption;
  supplier?: ContractItemSupplier;
  consumer?: ContractItemConsumer;
};

export type ContractItem = {
  eisId: number;
  name: string;
  idWithinContract: number;
  sumPrice: number;
  nds: number;
  smp: boolean;
  unitPrice: number;
  priceWithAppliedKoeff: number;
  count: number;
  unitMeasure: string;
  contract: Contract;
  ktru?: ContractItemKtru;
  maxIncreasePricePeriodRatioCoeff: number;
  maxIncreasePriceRatioCoeff: number;
  okpd?: ContractItemOkpd;
  characteristics: Characteristic[];
};
