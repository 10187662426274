import useSWR from 'swr';
import {
  AverageContractPrice,
  AverageContractPriceResponse,
  ContractPrice,
  ContractPriceResponse,
  Drug,
  KPPrice,
  LimitPrice,
  LimitPriceResponse,
  NmckDrugsByIdResponse,
  NmckDrugsRow,
} from '@/api/nmckDrugsApi/nmckDrugsApiTypes';
import {
  deleteData,
  downloadXlsx,
  fetcher,
  getData,
  postData,
  putData,
} from '@/api/commonApi/commonApi';
import { DictionaryFilter } from '@/widgets/DrugsGrid/components/DrugDictionaryModal/types';
import { isMoment } from 'moment';
import { formatDate } from '@/utils';
import { isNil, pick } from 'lodash';
import { AveragePriceFilter } from '@/widgets/DrugsGrid/components/DrugsGridExpandableTabs/AveragePriceTab/types';
import { DirectContractUpdateBody } from '@/api/reestrDirectApi/reestrDirectApiTypes';
import { BDUIFilterParam } from '@/components/BDUIFilter/types';
import { PaginationV1 } from '@/components/Table/TablePagination/types';
import { setURLParamsByBDUIFilters } from '@/utils/setURLParamsByBDUIFilters';
import { TokenBasedPaginationStateWithPageSize } from '@/api/commonApi/commonApiTypes';
import { setURLParamsByTokenPagination } from '@/utils/setURLParamsByTokenPagination';
import { LimitPriceAdditionalFilter } from '@/widgets/DrugsGrid/components/DrugsGridExpandableTabs/LimitPriceTab/types';

export const useGetNmckDrugsById = (id: number) => {
  return useSWR(`/nmck/drugs/${id}`, getData<NmckDrugsByIdResponse>, {
    revalidateOnFocus: false,
  });
};

export const useGetDrugsAutocompleteList = (prefix: string) => {
  const url = prefix ? `/nmck-drugs/autocomplete?prefix=${prefix}` : null;
  return useSWR(url, getData<string[]>, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });
};

export const useGetDrugs = ({ mnn, drugForm, dosage }: DictionaryFilter) => {
  const params = new URLSearchParams();
  if (mnn?.value) params.append('mnnName', mnn.value);
  if (drugForm?.value) params.append('drugForm', drugForm.value);
  if (dosage?.value) params.append('dosage', dosage.value);

  return useSWR(`/nmck-drugs/get-drugs?${params}`, getData<Drug[]>, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });
};

export const useGetDrugsDosage = ({ mnn, drugForm }: DictionaryFilter) => {
  const params = new URLSearchParams();
  if (mnn?.value) params.append('mnnTnName', mnn.value);
  if (drugForm?.value) params.append('drugForm', drugForm.value);

  return useSWR(`/nmck-drugs/autocomplete-dosage?${params}`, getData<string[]>, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });
};

export const useGetDrugsDrugForm = ({ mnn, dosage }: DictionaryFilter) => {
  const params = new URLSearchParams();
  if (mnn?.value) params.append('mnnTnName', mnn.value);
  if (dosage?.value) params.append('dosage', dosage.value);

  return useSWR(`/nmck-drugs/autocomplete-drug-form?${params}`, getData<string[]>, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });
};

export const useGetPricesByCodes = (
  nmckId: number,
  rowId: number,
  filters: BDUIFilterParam[],
  { source }: LimitPriceAdditionalFilter,
  { pageNumber, pageSize }: PaginationV1,
) => {
  const params = new URLSearchParams();

  params.append('source', source);
  params.append('pageNumber', String(pageNumber));
  params.append('pageSize', String(pageSize));
  setURLParamsByBDUIFilters(params, filters);

  return useSWR(
    `/nmck/drugs/${nmckId}/row/${rowId}/price-limits?${params}`,
    getData<LimitPriceResponse>,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    },
  );
};

export const useGetContracts = (
  rowId: number,
  filters: BDUIFilterParam[],
  pagination: TokenBasedPaginationStateWithPageSize,
) => {
  const params = new URLSearchParams();

  params.append('rowId', String(rowId));
  setURLParamsByBDUIFilters(params, filters);
  setURLParamsByTokenPagination(params, pagination);

  return useSWR(`/nmck-drugs/search-contracts?${params}`, getData<ContractPriceResponse>, {
    keepPreviousData: true,
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });
};

export const useGetAverageContracts = (filter: AveragePriceFilter) => {
  const params = new URLSearchParams();

  Object.entries(filter).forEach(([key, value]) => {
    if (!isNil(value)) {
      if (isMoment(value)) {
        params.append(key, formatDate(value, 'YYYY-MM-DD'));
      } else {
        params.append(key, String(value));
      }
    }
  });

  return useSWR(
    `/nmck-drugs/search-self-contracts?${params}`,
    getData<AverageContractPriceResponse>,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    },
  );
};

export const postNewNmckDrugs = (body: {
  copyFromId: number | null;
}): Promise<NmckDrugsByIdResponse> => {
  return postData('/nmck/drugs', body);
};

export const postNewNmckDrugRow = (nmckId: number) => {
  return postData(`/nmck/drugs/${nmckId}/row`);
};

export const updateNmckDrugsRow = (nmckId: number, row: NmckDrugsRow) =>
  putData<NmckDrugsRow>(`/nmck/drugs/${nmckId}/row/${row.id}`, {
    amount: row.amount,
    unit: row.unit,
    unitCode: row.unitCode,
    klpUUID: row.klpUUID,
    smnnUUID: row.smnnUUID,
    interchangeSmnnUuids: row.interchangeSmnnUuids,
    nds: row.nds,
    wholesaleAddition: row.wholesaleAddition,
  } as NmckDrugsRow);

export const updateNmckDrugs = (nmckId: number, nmck: NmckDrugsByIdResponse) => {
  const updateBody = pick(nmck, [
    'versionNumber',
    'name',
    'responsible',
    'calculationPrecision',
    'calculationMethod',
  ]);

  return putData<NmckDrugsByIdResponse, typeof updateBody>(`/nmck/drugs/${nmckId}`, updateBody);
};

export const deleteNmckDrugRow = (nmckId: number, rowId: number) => {
  return deleteData(`/nmck/drugs/${nmckId}/row/${rowId}`);
};

export const postLimitPrice = (
  nmckId: number,
  rowId: number,
  { priceLimitId, consumerTotal }: LimitPrice,
) => {
  return postData(`/nmck/drugs/${nmckId}/row/${rowId}/limit`, {
    priceLimitId,
    consumerTotal,
  });
};

export const deleteLimitPrice = (nmckId: number, rowId: number, priceLimitId: number) => {
  return deleteData(`/nmck/drugs/${nmckId}/row/${rowId}/limit/${priceLimitId}`);
};

export const updateLimitPrice = (nmckId: number, rowId: number, limitPrice: LimitPrice) => {
  return putData(`/nmck/drugs/${nmckId}/row/${rowId}/limit/${limitPrice.priceLimitId}`, {
    consumerTotal: limitPrice.consumerTotal,
  });
};

export const postContractPrice = (nmckId: number, rowId: number, contractPrice: ContractPrice) => {
  return postData(
    `/nmck/drugs/${nmckId}/row/${rowId}/contract`,
    pick<ContractPrice, keyof ContractPrice>(contractPrice, [
      'eisId',
      'externalSid',
      'procedureDrugId',
      'price',
      'nds',
      'wholesaleAddition',
      'maxIncreasePriceRatioCoeff',
      'maxIncreasePricePeriodRatioCoeff',
      'dosageUnits',
    ]),
  );
};

export const updateContractPrice = (
  nmckId: number,
  rowId: number,
  contractPrice: ContractPrice,
) => {
  return putData(
    `/nmck/drugs/${nmckId}/row/${rowId}/contract/${contractPrice.id}`,
    pick<ContractPrice, keyof ContractPrice>(contractPrice, [
      'eisId',
      'externalSid',
      'procedureDrugId',
      'price',
      'nds',
      'wholesaleAddition',
      'maxIncreasePriceRatioCoeff',
      'maxIncreasePricePeriodRatioCoeff',
      'dosageUnits',
    ]),
  );
};

export const deleteContractPrice = (nmckId: number, rowId: number, contractId: number) => {
  return deleteData(`/nmck/drugs/${nmckId}/row/${rowId}/contract/${contractId}`);
};

export const postKPPrice = (nmckId: number, rowId: number, kpPrice: KPPrice) => {
  return postData(
    `/nmck/drugs/${nmckId}/row/${rowId}/kp`,
    pick<KPPrice, keyof KPPrice>(kpPrice, [
      'supplier',
      'pricePerPackage',
      'nds',
      'wholesaleAddition',
      'amountInPackage',
      'dosageUnits',
    ]),
  );
};

export const updateKPPrice = (nmckId: number, rowId: number, kpPrice: KPPrice) => {
  return putData(
    `/nmck/drugs/${nmckId}/row/${rowId}/kp/${kpPrice.nmckDrugsMarketKpId}`,
    pick<KPPrice, keyof KPPrice>(kpPrice, [
      'supplier',
      'pricePerPackage',
      'nds',
      'wholesaleAddition',
      'amountInPackage',
      'dosageUnits',
    ]),
  );
};

export const deleteKPPrice = (nmckId: number, rowId: number, kpId: number) => {
  return deleteData(`/nmck/drugs/${nmckId}/row/${rowId}/kp/${kpId}`);
};

export const postAverageContractPrice = (
  nmckId: number,
  rowId: number,
  averageContractPrice: AverageContractPrice,
) => {
  return postData(
    `/nmck/drugs/${nmckId}/row/${rowId}/average`,
    pick<AverageContractPrice, keyof AverageContractPrice>(averageContractPrice, [
      'eisId',
      'externalSid',
      'procedureDrugId',
      'nds',
      'wholesaleAddition',
      'dosageUnits',
      'countPackaged',
    ]),
  );
};

export const updateAverageContractPrice = (
  nmckId: number,
  rowId: number,
  averageContractPrice: AverageContractPrice,
) => {
  return putData(
    `/nmck/drugs/${nmckId}/row/${rowId}/average/${averageContractPrice.id}`,
    pick<AverageContractPrice, keyof AverageContractPrice>(averageContractPrice, [
      'nds',
      'wholesaleAddition',
      'dosageUnits',
      'countPackaged',
    ]),
  );
};

export const deleteAverageContractPrice = (
  nmckId: number,
  rowId: number,
  averageContractId: number,
) => {
  return deleteData(`/nmck/drugs/${nmckId}/row/${rowId}/average/${averageContractId}`);
};

export const postDrugDirectContract = (
  nmckId: number,
  rowId: number,
  directContract: DirectContractUpdateBody,
) => {
  return postData(`/nmck/drugs/${nmckId}/row/${rowId}/direct-contract`, directContract);
};

export const postExistDrugDirectContract = (
  nmckId: number,
  rowId: number,
  averageContract: AverageContractPrice,
) => {
  return postData(
    `/nmck/drugs/${nmckId}/row/${rowId}/direct-contract/${averageContract.directContractId}`,
  );
};

export const updateDrugDirectContract = (
  nmckId: number,
  rowId: number,
  directContractId: number,
  directContract: DirectContractUpdateBody,
) => {
  return putData(
    `/nmck/drugs/${nmckId}/row/${rowId}/direct-contract/${directContractId}`,
    directContract,
  );
};

export const deleteDrugDirectContract = (
  nmckId: number,
  rowId: number,
  directContractId: number,
) => {
  return deleteData(`/nmck/drugs/${nmckId}/row/${rowId}/direct-contract/${directContractId}`);
};

export const updateLimitPriceGO = (priceRegistrationDecisionNumber: string) =>
  postData<{
    refreshed: boolean;
  }>('/price-limits-refresh', { priceRegistrationDecisionNumber });

export const getNmckDrugXlsx = async (nmckId: number, nmckName: string) => {
  const response = await fetcher(`/nmck/drugs/${nmckId}/xlsx`);
  await downloadXlsx(response, `НМЦК Лекарства ${nmckName}`.trim());
};

export const postRepeatedDrugCalculation = (nmckId: number) =>
  postData<NmckDrugsByIdResponse>(`/nmck/drugs/${nmckId}/recurrency`);
