import useSWR from 'swr';
import { CalculatioPenaltiesResponse, CurrentCBRateResponse } from './penaltiesCalcApiTypes';
import { getData, postData } from '@/api/commonApi/commonApi';

export const useGetCurrentCBRate = () => {
  return useSWR('/current-cb-rate', getData<CurrentCBRateResponse>, {
    revalidateOnFocus: false,
  });
};

export const calculatePenalties = async (body: {
  contractPrice: number;
  actuallyFulfilled: number;
  fromDate: string;
  toDate: string;
}): Promise<CalculatioPenaltiesResponse> => {
  return postData('/penalty-calculation', body);
};
