import { memo, useCallback } from 'react';
import { Button, Flex, Space } from 'antd';
import Plus from '/public/icons/plus.svg';
import { getNmckXlsx, postNewNmckRow, useGetNmckById } from '@/api/nmckApi/nmckApi';
import { formatNumber } from '@/utils/formatNumber';
import styles from './NmckGridFooter.module.scss';
import { eventsGTM, sendEventGTM } from '@/metrika';
import { ExportFile } from '@/components/ExportFile/ExportFile';

type Props = {
  id: string;
};

export const NmckGridFooter = memo(({ id }: Props) => {
  const { data, mutate: refreshCalculation } = useGetNmckById(id);
  const addRow = useCallback(async () => {
    if (data) {
      sendEventGTM(eventsGTM.nmckAddRow);
      await postNewNmckRow(id);
      await refreshCalculation();
    }
  }, [data, id, refreshCalculation]);

  const generateReport = useCallback(() => {
    sendEventGTM(eventsGTM.nmckDownloadExcel);
    data && getNmckXlsx(data.id, data.name);
  }, [data]);

  return (
    <div className={styles.wrapper}>
      <Button
        icon={<Plus />}
        className="button-with-icon"
        onClick={addRow}
      >
        Добавить позицию {data && data.rows.length + 1}
      </Button>
      <Flex
        align="center"
        gap={12}
      >
        <Flex align="center">
          <Space>
            <div className={styles.text}>Итого:</div>
            <div>{data && formatNumber(data.nmckTotal)} ₽</div>
          </Space>
        </Flex>
        <ExportFile onExportButtonClick={generateReport} />
      </Flex>
    </div>
  );
});

NmckGridFooter.displayName = 'NmckGridFooter';
