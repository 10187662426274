import { identity, pickBy } from 'lodash';
import { TokenBasedPagination } from '@/types/types';
import { OkpdKtruListFilter } from '@/widgets/OkpdKtru/types/types';

export const generateUrl = (url: string, options = {}) => {
  const params = new URLSearchParams(pickBy(options, identity));
  return `${url}?${params}`;
};

export const generateOkpdKtruQueryParams = ({
  pagination,
  filter,
}: {
  pagination?: TokenBasedPagination;
  filter: OkpdKtruListFilter;
}): string => {
  const params = new URLSearchParams();

  if (filter.code) {
    params.append('code', filter.code);
  } else {
    params.append('searchstring', filter.searchString);
  }
  if (pagination?.token) params.append('token', pagination.token);
  if (pagination?.count) params.append('count', String(pagination.count));
  filter.characteristics?.forEach(({ name, value }) => {
    params.append('characteristic', name);
    params.append('characteristicValue', value);
  });

  return params.toString();
};
