import { KtruResult } from '@/api/okpdApi/okpdApiTypes';
import { Flex } from 'antd';
import { Characteristics, NPAList, StandardContract } from '@/widgets/OkpdKtru/components';
import cardStyles from '../Card.module.scss';
import style from './KtruCard.module.scss';
import { memo, useCallback } from 'react';
import { pageRoutes } from '@/app/pageRoutes';
import {
  OkpdKtruPageTypes,
  SetOkpdKtruModalPageParamsType,
} from '@/components/OkpdFlowModal/types';
import { OkpdLink } from '@/components/OkpdFlowModal/OkpdLink/OkpdLink';
import { eventsGTM, sendEventGTM } from '@/metrika';

type KtruCardProps = {
  ktru: KtruResult;
  showNPAList?: boolean;
  showContracts?: boolean;
  setModalPageParams?: SetOkpdKtruModalPageParamsType;
};

export const KtruCard = memo(
  ({ ktru, showNPAList = true, showContracts = true, setModalPageParams }: KtruCardProps) => {
    const badgeLabel = ktru.isTemplate ? 'Укрупнённая' : 'Неукрупнённая';

    const handleCodeLinkClick = useCallback(() => {
      sendEventGTM(eventsGTM.ktruCardClick, {
        value: ktru.name,
        info: setModalPageParams ? 'modal' : 'page',
      });
      setModalPageParams &&
        setModalPageParams((prev) => ({
          ...prev,
          page: OkpdKtruPageTypes.KTRU,
          code: ktru.code,
          searchString: '',
        }));
    }, [ktru, setModalPageParams]);

    return (
      <Flex
        vertical
        gap={16}
        className={cardStyles.card}
      >
        <Flex
          vertical
          gap={8}
        >
          <Flex
            gap={16}
            align="center"
          >
            <OkpdLink
              href={`${pageRoutes.ktru}/${ktru.code}`}
              isLink={!setModalPageParams}
              className={cardStyles.cardLink}
              onClick={handleCodeLinkClick}
            >
              {ktru.code}
            </OkpdLink>
            <div className={style.badge}>{badgeLabel}</div>
          </Flex>
          <div className={cardStyles.cardName}>{ktru.name}</div>
        </Flex>
        <Characteristics characteristics={ktru.characteristics} />
        {showNPAList && <NPAList npa={ktru.npa} />}
        {showContracts && (
          <Flex
            justify={'space-between'}
            align={'center'}
          >
            <StandardContract standardContract={ktru.standardContracts} />
          </Flex>
        )}
      </Flex>
    );
  },
);

KtruCard.displayName = 'KtruCard';
