import useSWR from 'swr';
import { GuardCreateResponse, NmckGuard, NmckGuardUpdateBody } from './nmckGuardApiTypes';
import { downloadXlsx, fetcher, getData, postData, putData } from '../commonApi/commonApi';

export const postGuard = async (): Promise<GuardCreateResponse> => {
  return postData<GuardCreateResponse>('/nmck/guard');
};

export const putGuard = async (id: number, body: NmckGuardUpdateBody): Promise<NmckGuard> => {
  const url = `/nmck/guard/${id}`;
  return putData<NmckGuard, NmckGuardUpdateBody>(url, body);
};

export const getGuard = async (id: number): Promise<NmckGuard> => {
  return getData<NmckGuard>(`/nmck/guard/${id}`);
};

export const usePostGuard = () => {
  return useSWR('/nmck/guard', postGuard, {
    revalidateOnFocus: false,
  });
};

export const useGetGuard = (id: number) => {
  return useSWR(`/nmck/guard/${id}`, () => getGuard(id), {
    revalidateOnFocus: false,
  });
};

export const getGuardXlsx = async (id: number, nmckName?: string) => {
  const response = await fetcher(`/nmck/guard/${id}/xlsx`);

  const fileName = 'НМЦК_Новый расчет услуги охраны';
  await downloadXlsx(response, nmckName ? `${fileName} ${nmckName}` : fileName);
};
