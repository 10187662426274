import styles from '@/widgets/CalculationHistory/components/CalculationHistoryCard/CalculationHistoryCard.module.scss';

export const applyCardElementTemporaryHighlight = (nmckId: number) => {
  setTimeout(() => {
    const element = document.getElementById(`nmck-${nmckId}`);
    if (element) {
      element.classList.add(styles.highlighted);
      setTimeout(() => {
        element.classList.remove(styles.highlighted);
      }, 1000);
    }
  }, 4);
};
