import useSWR from 'swr';
import {
  NmckByIdResponse,
  NmckCompatibilityByIdResponse,
  NmckResponse,
  NmckRow,
  Proposition,
  PropositionType,
  TreeSelectDataResponse,
  UnitsMeasureResponse,
} from './nmckApiTypes';
import { ContractItemsResponse } from '@/api/nmckApi/nmckApiTypes';
import {
  deleteData,
  downloadXlsx,
  fetcher,
  getData,
  postData,
  putData,
} from '@/api/commonApi/commonApi';
import { KtruResponse } from '../okpdApi/okpdApiTypes';
import { isNumber, isString, pick } from 'lodash';
import { CompatibilityUpdateBody } from '@/api/planningCurrentApi/planningCurrentApiTypes';
import { NmckContractsFilter } from '@/components/NmckGrid/NmckGridContracts/types';
import { TokenBasedPaginationStateWithPageSize } from '@/api/commonApi/commonApiTypes';
import { formatDate } from '@/utils';
import { dateFormat } from '@/constants';

export const getNmckXlsx = async (id: number, nmckName: string) => {
  const response = await fetcher(`/nmck/${id}/xlsx`);
  await downloadXlsx(response, `НМЦК ${nmckName}`.trim());
};

export const getCompatibilityXlsx = async (
  id: number,
  nmckName: string,
  omitEmptyColumns: boolean = false,
  nationalProject: boolean = false,
) => {
  const params = new URLSearchParams();
  params.append('omitEmptyColumns', omitEmptyColumns.toString());
  params.append('nationalProject', nationalProject.toString());

  const response = await fetcher(`/nmck/${id}/compatibility-xlsx?${params}`);
  await downloadXlsx(response, `Совместимость НМЦК ${nmckName}`);
};

export const updateCompatibilityCell = (
  nmckId: string,
  rowId: number,
  body: CompatibilityUpdateBody,
) => {
  return postData(`/nmck/${nmckId}/row/${rowId}/compatibilityUserSelection`, body);
};

export const updateNmck = async ({ id, ...nmck }: NmckByIdResponse) => {
  const body = pick(nmck, [
    'versionNumber',
    'name',
    'responsible',
    'calculationPrecision',
    'calculationMethod',
  ]);

  return putData<NmckByIdResponse, typeof body>(`/nmck/${id}`, body);
};

export const updateNmckRow = async (nmckId: string | number, row: NmckRow) => {
  const updateBody = pick(row, [
    'name',
    'ktruCode',
    'okpdCode',
    'unitCode',
    'unit',
    'amount',
    'nkmiId',
    'nds',
  ]);

  return putData<NmckRow, typeof updateBody>(`/nmck/${nmckId}/row/${row.id}`, updateBody);
};

export const postNewNmck = async (body: {
  copyFromId: number | null;
  okpdCode?: string;
  ktruCode?: string;
  positionName?: string;
}): Promise<NmckByIdResponse> => {
  return postData('/nmck/market', body);
};

export const postNewNmckRow = async (nmckId: string) => {
  return postData<NmckByIdResponse>(`/nmck/${nmckId}/row`);
};

export const postNewProposition = async (nmckId: string) => {
  return postData<NmckByIdResponse>(`/nmck/${nmckId}/proposition`);
};

export const deleteNmckRow = async (nmckId: string, rowId: number) => {
  return deleteData<NmckByIdResponse>(`/nmck/${nmckId}/row/${rowId}`);
};

export const postCopyNmckRow = async (nmckId: string, rowId: number) => {
  return postData<NmckByIdResponse>(`/nmck/${nmckId}/row/${rowId}/copy`);
};

export const deleteNmckProposition = (nmckId: string, columnNumber: number) => {
  return deleteData<NmckByIdResponse>(`/nmck/${nmckId}/proposition/${columnNumber}`);
};

export const splitNmckById = async (
  id: string,
  nationalProject: boolean = false,
): Promise<number[]> => {
  const params = new URLSearchParams();
  params.append('nationalProject', nationalProject.toString());

  const url = `/nmck/${id}/split?${params}`;
  return await postData<number[]>(url);
};

export const useGetPurchaseMethod = () =>
  useSWR('/purchase-method', getData<TreeSelectDataResponse>, {
    revalidateOnFocus: false,
  });
export const useGetUnitsMeasure = (shouldFetch?: boolean, rowId?: number) => {
  const url = '/nmck-measurement-units' + (rowId ? `?rowId=${rowId}` : '');

  return useSWR(shouldFetch === false ? null : url, getData<UnitsMeasureResponse>, {
    revalidateOnFocus: false,
  });
};

export const useGetRegions = () =>
  useSWR('/region', getData<TreeSelectDataResponse>, {
    revalidateOnFocus: false,
  });

export const useGetContracts = ({
  row: { name, ktruCode, okpdCode },
  filter: {
    purchaseMethodIds,
    priceFrom,
    priceUpTo,
    unitMeasure,
    countFrom,
    countUpTo,
    conclusionDateStart,
    conclusionDateEnd,
    executionDateStart,
    executionDateEnd,
    regionIds,
    regionType,
    characteristics,
  },
  pagination,
}: {
  row: Pick<NmckRow, 'name' | 'okpdCode' | 'ktruCode'>;
  filter: NmckContractsFilter;
  pagination: TokenBasedPaginationStateWithPageSize;
}) => {
  const code = okpdCode ?? ktruCode;

  const params = new URLSearchParams();
  if (name) params.append('name', name);
  if (code) params.append('code', code);
  if (priceFrom) params.append('priceMin', priceFrom);
  if (priceUpTo) params.append('priceMax', priceUpTo);
  if (countFrom) params.append('countMin', countFrom);
  if (countUpTo) params.append('countMin', countUpTo);
  if (unitMeasure) params.append('unitMeasure', String(unitMeasure));
  if (conclusionDateStart) {
    params.append('conclusionDateStart', formatDate(conclusionDateStart, dateFormat.serverFormat));
  }
  if (conclusionDateEnd) {
    params.append('conclusionDateEnd', formatDate(conclusionDateEnd, dateFormat.serverFormat));
  }
  if (executionDateStart) {
    params.append('executionDateStart', formatDate(executionDateStart, dateFormat.serverFormat));
  }
  if (executionDateEnd) {
    params.append('executionDateEnd', formatDate(executionDateEnd, dateFormat.serverFormat));
  }
  regionIds.forEach((region) => {
    params.append('regionId', String(region));
  });
  purchaseMethodIds.forEach((pmId) => {
    params.append('purchaseMethodId', String(pmId));
  });
  params.append('regionType', regionType);
  params.append('pageSize', pagination.pageSize.toString());
  if (pagination.token) params.append('token', pagination.token);
  characteristics.forEach((item) => {
    params.append('characteristic', item.name);
    params.append('characteristicValue', item.value);
  });
  const queryString = `?${params.toString()}`;

  return useSWR(code ? `/contract/item${queryString}` : null, getData<ContractItemsResponse>, {
    keepPreviousData: true,
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });
};
export const useGetNmckById = (id: string) => {
  return useSWR(`/nmck/${id}`, (url) => getData<NmckByIdResponse>(url), {
    revalidateOnFocus: false,
  });
};

export const useGetNmckCompatibilityById = (
  id: string,
  omitEmptyColumns: boolean = false,
  nationalProject: boolean = false,
) => {
  const params = new URLSearchParams();
  params.append('omitEmptyColumns', omitEmptyColumns.toString());
  params.append('nationalProject', nationalProject.toString());

  return useSWR(
    `/nmck/${id}/compatibility?${params}`,
    (url) => getData<NmckCompatibilityByIdResponse>(url),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
  );
};

export const useGetKtru = ({
  searchString,
  nkmiId,
}: {
  searchString?: string;
  nkmiId?: number | string;
}) => {
  const params = new URLSearchParams();
  isString(searchString) && params.append('searchString', searchString);
  (isNumber(nkmiId) || isString(nkmiId)) && params.append('nkmiId', nkmiId.toString());

  const url = params.size ? `/ktru?${params}` : null;
  return useSWR(url, getData<KtruResponse>);
};

export const updateProposition = async (
  dataId: number | string,
  recordId: number,
  proposition: Proposition,
  propositionType: PropositionType,
  newPrice: number | null,
  newDescription: string,
  newForAllRows: boolean,
  contractId: number | null,
): Promise<void> => {
  const url = `/nmck/${dataId}/row/${recordId}/proposition/${proposition.columnNumber}`;

  const body = {
    type: propositionType,
    price: newPrice,
    description: newDescription,
    contractId: contractId,
    forAllRows: newForAllRows,
  };

  await putData(url, body);
};

export const usePostProposition = async (id: string): Promise<NmckByIdResponse> => {
  const url = `/nmck/${id}/proposition`;
  return await postData<NmckByIdResponse>(url);
};

export const useGetNmcks = ({
  page,
  size,
  searchString,
}: {
  page: number;
  size: number;
  searchString: string;
}) => {
  const params = new URLSearchParams();
  params.append('page', size !== -1 ? '1' : page.toString());
  params.append('count', size === -1 ? '10' : size.toString());
  searchString && params.append('searchstring', searchString);

  return useSWR(`/nmck?${params}`, getData<NmckResponse>);
};

export const deleteNmck = async (id: number) => {
  return deleteData(`/nmck/${id}`);
};
