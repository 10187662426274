import { Moment } from 'moment';

export type Characteristic = { name: string; value: string };

export enum PeriodType {
  CONCLUDED = 'concluded',
  EXECUTION = 'execution',
}

export enum RegionType {
  SUPPLIER = 'SUPPLIER',
  BUYER = 'BUYER',
}

export type NmckContractsFilter = {
  purchaseMethodIds: number[];
  priceFrom: string;
  priceUpTo: string;
  unitMeasure?: number;
  conclusionDateStart?: Moment;
  conclusionDateEnd?: Moment;
  executionDateStart?: Moment;
  executionDateEnd?: Moment;
  countFrom: string;
  countUpTo: string;
  regionIds: number[];
  regionType: RegionType;
  characteristics: Characteristic[];
};
