import { Dispatch, memo, SetStateAction, useCallback, useMemo, useState } from 'react';
import { ConfigProvider, Flex, Table, TableProps, Typography } from 'antd';
import styles from './OkpdKtruSuppliersTable.module.scss';
import CopyIcon from '/public/icons/copy.svg';
import colors from '@/styles/colors.module.scss';
import { formatPrice } from '@/utils/formatPrice';
import ExportDiagonalIcon from '/public/icons/exportDiagonal.svg';
import Link from 'next/link';
import { OkpdKtruRequestKP } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruRequestKP/OkpdKtruRequestKP';
import {
  KtruDetail,
  OkpdDetail,
  SuppliersResponse,
  SupplierResult,
} from '@/api/okpdApi/okpdApiTypes';
import { TableFooter } from '@/components/Table/TableFooter/TableFooter';
import { PageSizeChangeCallbackType } from '@/components/Table/TablePagination/types';
import { useGetSuppliers } from '@/api/okpdApi/okpdApi';
import { SuppliersFilter } from '@/components/OkpdKtruDetail/OkpdKtruSuppliers/OkpdKtruSuppliersFilter/OkpdKtruSuppliersFilter';
import { useTokenBasedDataList } from '@/hooks';
import { LoadMoreCallbackType } from '@/components/Table/TablePagination/TableTokenBasedPagination/types';
import {
  TokenBasedPaginationStateWithPageSize,
  TokenBasedPaginationTokenValue,
} from '@/api/commonApi/commonApiTypes';
import cx from 'classnames';
import { preventEventPropagation } from '@/utils/preventEventPropagation';

type OkpdKtruSuppliersTable = {
  pagination: TokenBasedPaginationStateWithPageSize;
  filter: SuppliersFilter;
  setPagination: Dispatch<SetStateAction<TokenBasedPaginationStateWithPageSize>>;
  okpdKtruDetailData: OkpdDetail | KtruDetail;
};

const copyable = {
  tooltips: ['Нажмите чтобы скопировать', 'Скопировано'],
  icon: [
    <CopyIcon
      key="copy-icon"
      className={styles.copyIcon}
    />,
  ],
};

const theme = {
  components: {
    Table: {
      headerBg: colors.white,
      headerColor: colors.light_gray,
    },
  },
};

const columns = [
  {
    title: 'Поставщик',
    key: 'name',
    width: '480px',
    render: (_: unknown, { name, inn, kpp, address }: SupplierResult) => (
      <Flex
        vertical
        gap={4}
      >
        <div className={styles.name}>{name}</div>
        <Flex
          align="center"
          gap={4}
          wrap
        >
          <div className={styles.gray}>ИНН {inn}</div>
          <div className={styles.gray}>КПП {kpp}</div>
          <div className={styles.gray}>{address}</div>
        </Flex>
      </Flex>
    ),
  },
  {
    title: 'Исполнено всего',
    key: 'contractsFinished',
    width: '200px',
    align: 'center' as const,
    ellipsis: true,
  },
  {
    title: 'Последний исполненный контракт',
    key: 'price',
    width: '300px',
    render: (_: unknown, { price, lastContractRegNum }: SupplierResult) => (
      <Flex
        gap={16}
        align="center"
        justify="flex-end"
      >
        <span>{formatPrice(price)}</span>
        <Link
          href={`https://zakupki.gov.ru/epz/contract/contractCard/common-info.html?reestrNumber=${lastContractRegNum}`}
          target="_blank"
          className={styles.linkRegNum}
          onClick={preventEventPropagation}
        >
          <ExportDiagonalIcon />
          <span>Последний исп. контракт</span>
        </Link>
      </Flex>
    ),
  },
  {
    title: 'E-mail',
    key: 'email',
    width: '260px',
    render: (email: string) => (
      <Typography.Text
        copyable={copyable}
        className={styles.copyable}
        onClick={preventEventPropagation}
      >
        {email}
      </Typography.Text>
    ),
  },
  {
    title: 'Телефон',
    key: 'phone',
    render: (phone: string) => (
      <Typography.Text
        copyable={copyable}
        className={styles.copyable}
        onClick={preventEventPropagation}
      >
        {phone}
      </Typography.Text>
    ),
  },
].map((c) => ({ ...c, dataIndex: c.key }));

export const OkpdKtruSuppliersTable = memo(
  ({ pagination, setPagination, filter, okpdKtruDetailData }: OkpdKtruSuppliersTable) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState<SupplierResult['name'][]>([]);

    const { list, isLoading, token, hasMoreResults } = useTokenBasedDataList<
      SupplierResult,
      SuppliersResponse
    >({
      response: useGetSuppliers(filter, pagination),
      resetDeps: [filter, pagination.pageSize],
    });

    const selectedEmails = useMemo(
      () => selectedRowKeys.map((name) => list.find((item) => item.name === name)?.email ?? ''),
      [selectedRowKeys, list],
    );

    const rowSelection: TableProps['rowSelection'] = {
      columnWidth: '64px',
      selectedRowKeys,
      onChange: (newSelectedRowKeys) =>
        setSelectedRowKeys(newSelectedRowKeys as SupplierResult['name'][]),
    };

    const onLoadMore: LoadMoreCallbackType = useCallback(
      (token: TokenBasedPaginationTokenValue) => {
        setPagination((prev) => ({
          ...prev,
          token,
        }));
      },
      [setPagination],
    );

    const onPageSizeChange: PageSizeChangeCallbackType = useCallback(
      (pageSize: number) => {
        setPagination((prev) => ({
          ...prev,
          pageSize,
          token: null,
        }));
      },
      [setPagination],
    );

    const tokenBasedPaginationProps = useMemo(
      () => ({
        pagination: { token, hasMoreResults },
        pageSize: pagination.pageSize,
        isDataLoading: isLoading,
        onPageSizeChange,
        onLoadMore,
      }),
      [hasMoreResults, isLoading, onLoadMore, onPageSizeChange, pagination.pageSize, token],
    );

    const onRow: TableProps['onRow'] = useCallback(
      ({ name }: SupplierResult) => ({
        onClick: () => {
          if (selectedRowKeys.includes(name)) {
            setSelectedRowKeys((prev) => prev.filter((key) => key !== name));
          } else {
            setSelectedRowKeys((prev) => [...prev, name]);
          }
        },
      }),
      [selectedRowKeys],
    );

    const footer: TableProps['footer'] = useCallback(
      () => (
        <Flex
          align="center"
          justify="space-between"
        >
          <OkpdKtruRequestKP
            receivers={selectedEmails}
            okpdKtruDetailData={okpdKtruDetailData}
          />
          <TableFooter tokenBasedPagination={tokenBasedPaginationProps} />
        </Flex>
      ),
      [okpdKtruDetailData, selectedEmails, tokenBasedPaginationProps],
    );

    return (
      <ConfigProvider theme={theme}>
        <Table
          columns={columns}
          dataSource={list}
          loading={isLoading}
          className={cx(styles.table, 'table-with-sticky-footer')}
          rowSelection={rowSelection}
          onRow={onRow}
          rowKey="name"
          pagination={false}
          footer={footer}
        />
      </ConfigProvider>
    );
  },
);

OkpdKtruSuppliersTable.displayName = 'OkpdKtruSuppliersTable';
